// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;

// Load Styles
import "../scss/main.scss";

// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

// import NiceSelect from "nice-select2";

// Load Bootstrap init
// import {initBootstrap} from "./bootstrap.js";

import "bootstrap";


// require('bootstrap-icons/font/bootstrap-icons.css');
import "../../node_modules/@fortawesome/fontawesome-free/css/brands.css";
import "../../node_modules/@fortawesome/fontawesome-free/css/regular.css";
import "../../node_modules/@fortawesome/fontawesome-free/css/solid.css";
import "../../node_modules/@fortawesome/fontawesome-free/js/all.js";
// import FastGlob from 'fast-glob';

// Loading bootstrap with optional features
// initBootstrap({
//   tooltip: true,
//   popover: true,
//   toasts: true,
//   modal: true,
// });

// Your app code
// console.log(`Hello ${process.env.HELLO}`);

$(document).ready(function () {
  $(window).scroll(function (e) {
    //navbar shrink
    var siteHeader = $("#siteHeader");
    $(window).scroll(function () {
      if ($(document).scrollTop() > 50) {
        siteHeader.addClass("site-header--shrinked");
      } else {
        siteHeader.removeClass("site-header--shrinked");
      }
    });

    // Scroll Top fade in out
    if ($(document).scrollTop() > 200) {
      $(".scroll-to-top-button").addClass("scroll-to-top-button--show");
    } else {
      $(".scroll-to-top-button").removeClass("scroll-to-top-button--show");
    }
  });

  // Header and Stikcy Ecom bar offcanvas function
  const siteHeaderCartBtn = $(".site-header__btn--cart");
  const ecomBarCartBtn = $(".ecom-bottom-bar__btn--cart");
  const cartOffcanvas = $("#offcanvasCart");

  siteHeaderCartBtn.on("click", function (e) {
    e.preventDefault();
    openCartOffcanvas();
  });

  ecomBarCartBtn.on("click", function (e) {
    e.preventDefault();
    openCartOffcanvas();
  });

  function openCartOffcanvas() {
    cartOffcanvas.offcanvas("show");
  }

  // Hero Swiper
  var swiperHero = new Swiper(".hero-swiper", {
    // Optional parameters
    direction: "horizontal",
    loop: true,
    autoplay: true,
    pauseOnMouseEnter: true,
    speed: 3000,
    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderCustom: function (swiper, current, total) {
        var names = [];
        $(".swiper-wrapper .swiper-slide").each(function (i) {
          names.push($(this).data("name"));
        });
        var text = "<ul>";
        for (let i = 1; i <= total; i++) {
          if (current == i) {
            text += `<li class="swiper-pagination-bullet active">${names[i]}</li>`;
          } else {
            text += `<li class="swiper-pagination-bullet">${names[i]}</li>`;
          }
        }
        text += "</ul>";
        return text;
      },
    },

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  // Testimonial Swiper
  var swiperTestimonial = new Swiper(".testimonial-swiper", {
    // Optional parameters
    direction: "horizontal",
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 5000,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 12,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      1400: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderCustom: function (swiper, current, total) {
        var names = [];
        $(".swiper-wrapper .swiper-slide").each(function (i) {
          names.push($(this).data("name"));
        });
        var text = "<ul>";
        for (let i = 1; i <= total; i++) {
          if (current == i) {
            text += `<li class="swiper-pagination-bullet active">${names[i]}</li>`;
          } else {
            text += `<li class="swiper-pagination-bullet">${names[i]}</li>`;
          }
        }
        text += "</ul>";
        return text;
      },
    },

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next--testimonial",
      prevEl: ".swiper-button-prev--testimonial",
    },
  });

  // Services Swiper
  var swiperServices = new Swiper(".swiper--services", {
    // Optional parameters
    direction: "horizontal",
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 5000,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 12,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 24,
      },
    },
    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderCustom: function (swiper, current, total) {
        var names = [];
        $(".swiper-wrapper .swiper-slide").each(function (i) {
          names.push($(this).data("name"));
        });
        var text = "<ul>";
        for (let i = 1; i <= total; i++) {
          if (current == i) {
            text += `<li class="swiper-pagination-bullet active">${names[i]}</li>`;
          } else {
            text += `<li class="swiper-pagination-bullet">${names[i]}</li>`;
          }
        }
        text += "</ul>";
        return text;
      },
    },

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  // Header Mobile Nav Dropdown Menu
  var navLinks = $(".site-header__mobile-nav > ul li a");
  navLinks.each(function () {
    $(this).next().first().slideUp(0);
  });
  navLinks.each(function () {
    $(this).on("click", function (e) {
      if ($(this).hasClass("has-dropdown")) {
        e.preventDefault();
        $(this).parent().siblings().find("ul").slideUp(300);
        $(this).parent().siblings().find("svg").removeClass("fa-minus").addClass("fa-plus");
        $(this).next().first().slideToggle(300);
        if ($(this).find("svg").hasClass("fa-plus")) {
          $(this).find("svg").removeClass("fa-plus").addClass("fa-minus");
        } else {
          $(this).find("svg").removeClass("fa-minus").addClass("fa-plus");
        }
      }
    });
  });

  // Accordion
  $(".set > a").on("click", function () {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this).siblings(".content").slideUp(200);
      $(".set > a svg").removeClass("fa-minus").addClass("fa-plus");
    } else {
      $(".set > a svg").removeClass("fa-minus").addClass("fa-plus");
      $(this).find("svg").removeClass("fa-plus").addClass("fa-minus");
      $(".set > a").removeClass("active");
      $(this).addClass("active");
      $(".content").slideUp(200);
      $(this).siblings(".content").slideDown(200);
    }
  });

  //Click event to scroll to top
  $(".scroll-to-top-button").click(function () {
    scrollToTop(0, 500);
    return false;
  });

  function scrollToTop(offset, duration) {
    $("html, body").animate({ scrollTop: offset }, duration);
    return false;
  }



  // $('#rqfSelectSubject').select2();

  // Request a quote form - dependency on select subject
  var selectSubjectOption = $("#rqfSelectSubject");
  selectSubjectOption.on("change", function () {
    // console.log($(this).val());
    if ($(this).val() === "service") {
      $("#rqfSelectService").closest(".form__field").slideDown(300);
    } else {
      $("#rqfSelectService").closest(".form__field").slideUp(300);
    }
  });

  // contact form - dependency on select subject
  var selectSubjectOption2 = $("#cfSelectSubject");
  selectSubjectOption2.on("change", function () {
    // console.log($(this).val());
    if ($(this).val() === "service") {
      $("#cfSelectService").closest(".form__field").slideDown(300);
    } else {
      $("#cfSelectService").closest(".form__field").slideUp(300);
    }
  });

  // Software search form
  var searchInputField = $(".software-search-form .form__input");
  //var softwareTitles = $('.softwares-list-table tbody tr td:nth-child(2)');
  var softwareTitles = $(".soft-item .soft-item__title a");
  searchInputField.on("keyup", function () {
    // console.log($(this).val());
    var inpVal = $(this).val().toUpperCase();

    softwareTitles.each(function () {
      var titleVal = $(this).html().toUpperCase();
      // console.log(titleVal);
      if (titleVal.indexOf(inpVal) <= -1) {
        $(this).closest(".soft-item").hide();
        checkEmptyItems();
      } else {
        $(this).closest(".soft-item").show();
        checkEmptyItems();
      }
    });
  });
  function checkEmptyItems() {
    console.log($(".soft-item").length);
    if ($(".soft-item").length <= 0) {
      $("soft-count--empty").removeClass("d-none");
      console.log("empty");
    } else {
      $("soft-count--empty").addClass("d-none");
      console.log("not empty");
    }
  }

  // login and register form sliding
  var btnGoToRegister = $(".btn--to-register");
  var btnGoToLogin = $(".btn--to-login");
  var userActionFormsWrapper = $(".user-action-forms-wrapper");
  var isFormSlided = false;

  btnGoToRegister.on("click", function () {
    userActionFormsWrapper.addClass("user-action-forms-wrapper--slided");
    isFormSlided = true;
    scrollToTop(0, 500);
  });

  btnGoToLogin.on("click", function () {
    if (isFormSlided) {
      userActionFormsWrapper.removeClass("user-action-forms-wrapper--slided");
      isFormSlided = false;
      scrollToTop(0, 500);
    }
  });

  // show password button function
  var showPasswordButtons = $(".form .button--show-password");
  var showPassFlag = false;
  showPasswordButtons.each(function () {
    $(this).on("click", function (e) {
      e.preventDefault();
      if (!showPassFlag) {
        $(this).siblings("input").attr("type", "text");
        $(this).find("i").removeClass("bi-eye").addClass("bi-eye-slash");
        showPassFlag = true;
      } else {
        $(this).siblings("input").attr("type", "password");
        $(this).find("i").removeClass("bi-eye-slash").addClass("bi-eye");
        showPassFlag = false;
      }
    });
  });

  // Search Form Mobile
  const searchFormContainerEl = $(".search-form-container");
  const searchBtnEl = $(".site-header__btn--search");
  var isSearchFormContainerOpened = false;
  searchBtnEl.on("click", function (e) {
    e.preventDefault();
    if (!isSearchFormContainerOpened) {
      searchFormContainerEl.slideDown(300);
      $("html").addClass("scroll--disabled backdrop--active");
      isSearchFormContainerOpened = true;
    } else {
      searchFormContainerEl.slideUp(300);
      $("html").removeClass("scroll--disabled backdrop--active");
      isSearchFormContainerOpened = false;
    }
  });

  $("html").click(function (event) {
    if (isSearchFormContainerOpened) {
      if ($(event.target).closest(".search-form-container, .site-header").length === 0) {
        searchFormContainerEl.slideUp(300);
        $("html").removeClass("scroll--disabled backdrop--active");
        isSearchFormContainerOpened = false;
      }
    }
  });


});


